<template>
  <div class="wrapper">
    <v-row no-gutters style="height: 100%">
      <div
        class="col-lg-6 site-banner"
        style="
          background-image: url('https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/e7f911620268d42205f453d67ad6c80c.jpg');
          background-position: center center;
          background-size: contain;
          display: flex;
          justify-content: center;
        "
      ></div>
      <div class="col-lg-6 site-content">
        <v-row no-gutters>
          <div class="col-lg-8 col-md-10 col-sm-12 mx-auto my-5">
            <div class="text-center mb-4">
              <h2>¡Bienvenido!</h2>
            </div>
            <div class="card">
              <v-form action class="form" ref="form" v-model="valid">
                <v-text-field
                  v-model="model.email"
                  :rules="[rules.required, rules.email]"
                  prepend-inner-icon="mdi-email-lock"
                  label="Correo electrónico"
                  hint=""
                  counter
                ></v-text-field>
                <v-text-field
                  v-model="model.password"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="show ? 'text' : 'password'"
                  prepend-inner-icon="mdi-lock"
                  label="Contraseña"
                  hint=""
                  counter
                  @click:append="show = !show"
                ></v-text-field>
                <v-checkbox
                  v-model="model.remenberMe"
                  :label="model.remenberMe ? 'Recordar sesión' : 'No recordar sesión'"
                ></v-checkbox>
                <div
                  v-if="dialog"
                  class="custom-control custom-control-alternative custom-checkbox"
                >
                  <div class="darken-2 text-justify pr-16" border="bottom">
                    <span class="red--text">{{ error }}</span>
                  </div>
                </div>
                <div style="display: flex; justify-content: center; align-items: center">
                  <button
                    style="color: #fff; background-color: #009ada; border-color: #009ada"
                    :disabled="!valid"
                    @click.prevent="validate"
                    class="btn btn-primary"
                  >
                    Entrar<v-icon dark right> mdi-checkbox-marked-circle </v-icon>
                  </button>
                </div>
              </v-form>
              <div class="text-center text-muted mb-0" style="margin-top: 20px">
                <a href="/session/forgot-password"
                  >¿Haz olvidado tu contraseña? <br /><small
                    >Haz click aquí para restaurarla</small
                  >
                </a>
              </div>
              <hr style="margin: 10px" />
              <div class="text-center text-muted">
                <h5 style="margin-bottom: 20px">¿Aún no tienes cuenta?</h5>
                <a
                  href="/session/sign-up"
                  class="btn btn-success mb-3"
                  style="background-color: #5b5b5e; border-color: #5b5b5e"
                  >Regístrate</a
                ><br />
                <button
                  title="Ir a la pagina principal"
                  style="color: #fff; background-color: #009ada; border-color: #009ada"
                  @click.prevent="$router.push('/')"
                  class="btn btn-primary"
                >
                  <v-icon dark style="margin: 0px"> mdi-arrow-collapse-right </v-icon>
                  <v-icon dark style="margin: 0px"> mdi-home </v-icon>
                </button>
              </div>
              <!-- <div class="card-body" style="padding: 20px 10px; width: 80%">
              </div> -->
            </div>
          </div>
        </v-row>
        <!-- <div class="content" style="padding-top: 0px">
          </div> -->
      </div>
    </v-row>
  </div>
</template>

<script>
  var config = {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: '',
    },
  }

  export default {
    name: 'Login',
    props: ['redirect', 'access'],
    data: () => ({
      error: '',
      valid: true,
      dialog: false,
      loading: false,
      show: false,
      password: 'Password',
      rules: {
        required: (value) => !!value || 'Requerido',
        min: (v) => v.length >= 6 || 'Escriba por lo menos 6 caracteres',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'El formato de correo electrónico inválido'
        },
      },
      model: {
        email: '',
        remenberMe: '',
        emailRules: [
          (v) => !!v || 'Correo electrónico es requerido',
          (v) => /.+@.+\..+/.test(v) || 'El formato de correo electrónico es invalido',
        ],
        password: '',
        passwordRules: [(v) => !!v || 'La contraseña es requerida'],
      },
      lazy: false,
    }),
    created() {
      this.$session.destroy()
    },
    methods: {
      validate() {
        if (this.$refs.form.validate()) {
          this.login()
        }
      },
      login() {
        axios
          .post(process.env.VUE_APP_API_DIRECTORY + 'auth/login', {
            email: this.model.email,
            password: this.model.password,
          })
          .then((response) => {
            this.$session.start()
            this.$session.set('tokenSession', response.data.access_token)
            this.$session.set('userId', response.data.user.id)
            this.$session.set('userName', response.data.user.name)
            this.$session.set('userLastname', response.data.user.lastname)
            this.$session.set('userAvatar', response.data.user.avatar)
            this.$session.set('roles', response.data.user.roles)
            if (this.$route.query.redirect && this.$route.query.redirect != '') {
              this.$router.push(this.$route.query.redirect)
            } else {
              this.$router.push('/admin/panel')
            }
            this.loading = false
          })
          .catch((error) => {
            if (error.response) {
              switch (error.response.status) {
                case 401:
                  this.dialog = true
                  this.error = 'Correo electrónico o contraseña incorrecta'
                  break
                case 422:
                  this.dialog = true
                  this.error = 'Datos inválidos'
                  break
                default:
                  this.dialog = true
                  this.error = 'Ocurrió un error'
                  break
              }
            }
            this.loading = false
          })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .wrapper {
    background: #cbd8e1;
    height: 100vh;
    // padding: 25px;
    max-width: 1440px;
    margin: 0 auto;
  }
  .site-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .form {
    width: 80%;
  }
  .card {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 10px;
    box-shadow: 0 15px 35px rgb(50 50 93 / 20%), 0 5px 15px rgb(0 0 0 / 15%);
    border: 2px solid rgba(0, 0, 0, 0.1);
  }
  .v-text-field__details {
    display: none;
    flex: 1 0 auto;
    max-width: 100%;
    min-height: 14px;
    overflow: hidden;
  }
  .col-lg-4,
  .col-md-6,
  .col-sm-12 {
    padding: 0px auto !important;
  }
  .theme--light.v-input {
    color: rgba(0, 0, 0, 0.87);
    padding: 0px;
    margin: 10px auto;
  }
  .v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
    display: flex;
    flex: 1 1 auto;
    position: relative;
    width: 100%;
  }

  .swal2-content {
    max-height: 250px;
    min-height: auto;
    overflow-y: auto;
  }
  dd {
    font-size: 15px;
  }
  dt {
    font-size: 16px;
  }
  .swal2-icon {
    margin: auto 1.875em;
  }
  .swal2-header {
    font-size: 14px;
  }
  .swal2-actions {
    margin: 0px;
  }
</style>
